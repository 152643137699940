<script>
// Extensiones
import View from '@/components/View'

// Mixins
import LoadSections from '@/mixins/load-sections'

export default {
  name: 'Contact',

  metaInfo: { title: 'Contact'},

  extends: View,

  mixins: [
    LoadSections([
      'contact-section',
    ]),
  ],

  props: {
    id: {
      type: String,
      default: 'contact',
    },
  },
}
</script>